// const jwtDecode = require('jwt-decode')
export default {
  setSiteHostname(state, { data }) {
    if (data) {
      state.siteHostname = data.hostname
    } else {
      state.siteHostname = null
    }
  },
  setLoading(state, data) {
    if (data) {
      state.loading = data
    } else {
      state.loading = null
    }
  },
  setSiteDetails(state, data) {
    if (data) {
      state.siteDetails = data
    } else {
      state.siteDetails = null
    }
  },
  setPageDetails(state, data) {
    if (data) {
      state.pageDetails = data
    } else {
      state.pageDetails = null
    }
  },
  setErrorMsg(state, data) {
    if (data) {
      state.errMsg = data
    } else {
      state.errMsg = null
    }
  },
  siteShowCaseData(state, data) {
    state.siteShowCaseData = data
  },
  // ---------------------------------Admin-------------------------
  setAdminToken(state, data) {
    if (data) {
      state.token = data
    } else {
      state.token = null
    }
  },
  sitesList(state, { data, headers }) {
    if (data.length !== 0) {
      state.SiteCount = headers['content-range'].split('/')[1]
      state.siteList = data
    } else {
      state.SiteCount = 0
      state.siteList = null
    }
  }
}
