<template>
  <footer id="footer" test="footer" class="bg-dark text-light pl-3 pr-3">
    <b-row>
      <b-col md="6" class="a">
        © {{ currentYear }} WebAuditor, All rights reserved.
      </b-col>
      <b-col md="6" class="b">
        Made with <b-img class="heart" src="~/static/images/heart.svg" /> by
        <a href="https://www.improwised.com" target="_blank">
          Improwised Technologies
        </a>
      </b-col>
    </b-row>
  </footer>
</template>
<script>
import '@/static/css/app.css'
export default {
  name: 'Footer',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>
<style>
#footer {
  margin-top: auto;
}
.heart {
  height: 1.42rem;
}
a {
  color: #fab415;
}
</style>
