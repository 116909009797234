import cookieparser from 'cookieparser'
import Cookies from 'js-cookie'
const qs = require('qs')
const specificHeaders = {
  singleObject: { Accept: 'application/vnd.pgrst.object+json' },
  count: { Prefer: 'count=exact' }
}
function showToast(ctx, message, variant = `success`) {
  ctx._vm.$bvToast.toast(`${message}`, {
    title: ` `,
    variant: `${variant}`,
    toaster: 'b-toaster-bottom-right',
    solid: true,
    appendToast: true
  })
}

export default {
  async addWebsite({ commit }, website) {
    commit('setLoading', true)
    await this.$axios({
      method: 'post',
      url: `api/rpc/add_new_site`,
      data: {
        hostname: website.hostname,
        crawlinterval: parseInt(process.env.CRAWL_INTERVAL)
      },
      headers: {
        ...specificHeaders.singleObject
      }
    })
      .then((response) => {
        website.hostname = response.data.hostname
        commit('setSiteHostname', response)
      })
      .catch((error) => {
        commit('setLoading', false)
        commit('setErrorMsg', error.response.data.error)
      })
  },
  async checkSiteStatus({ commit }, website) {
    let loading = true
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    await delay(1000)
    while (loading === true) {
      await this.$axios
        .$get('api/sites', {
          params: {
            hostname: `eq.${website.hostname}`
          }
        })
        .then((response) => {
          if (response.status === 'Redirect') {
            website.hostname = response.redirect_hostname
          } else if (
            response.status === 'Done' ||
            response.status === 'Partial'
          ) {
            loading = false
            this.$router.push({ path: '/' + response.hostname })
          } else if (response.status === 'Timeout') {
            loading = false
            commit('setErrorMsg', response.error_msg)
          } else if (response.status === 'Error') {
            loading = false
            commit('setErrorMsg', response.error_msg)
          }
        })
        .catch((error) => {
          console.log(error)
          loading = false
        })
      await delay(1000)
    }
  },
  async getSiteDetails({ commit }, { params, redirect }) {
    await this.$axios
      .$get('api/sites', {
        paramsSerializer: (params) => {
          return qs.stringify(params, { allowDots: true })
        },
        params: {
          hostname: `eq.${params.hostname}`,
          select: 'score,sites_errors(*),page_site_id_fkey(url,page_id,score)',
          page_site_id_fkey: {
            order: 'url.asc'
          },
          sites_errors: {
            order: 'rule_status.asc'
          }
        }
      })
      .then((response) => {
        if (Object.keys(response).length === 0) {
          redirect({ name: 'verify', query: { hostname: params.hostname } })
        } else {
          commit('setSiteDetails', response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async refreshSiteDetails({ commit }, params) {
    const delay = (s) => new Promise((resolve) => setTimeout(resolve, 1000 * s))
    await delay(1)
    let status = 'Partial'
    let count = 0
    let timeoutInSec = 0
    while (status === 'Partial') {
      await this.$axios
        .$get('api/sites', {
          paramsSerializer: (params) => {
            return qs.stringify(params, { allowDots: true })
          },
          params: {
            hostname: `eq.${params.hostname}`,
            select:
              'score,sites_errors(*),page_site_id_fkey(url,page_id,score)',
            page_site_id_fkey: {
              order: 'url.asc'
            },
            sites_errors: {
              order: 'rule_status.asc'
            }
          }
        })
        .then((response) => {
          status = response.status
          commit('setSiteDetails', response)
        })
        .catch((error) => {
          console.log(error)
        })
      if (count % 15 === 0) {
        timeoutInSec++
      }
      await delay(timeoutInSec)
      count++
    }
  },
  async getPageDetails({ commit }, { params, redirect }) {
    await this.$axios
      .$get('api/pages', {
        paramsSerializer: (params) => {
          return qs.stringify(params, { allowDots: true })
        },
        params: {
          page_id: `eq.${params.id}`,
          select: 'url,page_id,score,pages_errors(*)',
          pages_errors: { order: 'rule_status.asc' },
          order: 'url.asc'
        },
        headers: {
          ...specificHeaders.singleObject
        }
      })
      .then((response) => {
        if (Object.keys(response).length === 0) {
          redirect({
            name: 'hostname',
            params: {
              hostname: params.hostname
            }
          })
        } else {
          commit('setPageDetails', response)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  async getSiteShowCaseData({ commit }) {
    await this.$axios({
      method: 'get',
      url: 'api/show_case'
    })
      .then((response) => {
        commit('siteShowCaseData', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  // ---------------------------Admin------------------------
  async verifyAdminUser({ commit }, adminData) {
    await this.$axios({
      method: 'post',
      url: `api/rpc/verify_admin_user`,
      data: {
        token: adminData.token
      },
      headers: {
        ...specificHeaders.singleObject
      }
    })
      .then(({ data }) => {
        commit('setAdminToken', data)
        Cookies.set('token', data)
        this.$router.push({
          path: '2af3RT5Op1vb-7Weos385-TY94/sites?page_no=1'
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  async addWebsiteAdmin({ commit, state }, website) {
    commit('setLoading', true)
    await this.$axios({
      method: 'post',
      url: `api/rpc/add_new_site`,
      data: {
        hostname: website.hostname,
        crawlinterval: 0
      },
      headers: {
        Authorization: 'Bearer ' + state.token,
        ...specificHeaders.singleObject
      }
    })
      .then((response) => {
        website.hostname = response.data.hostname
        commit('setSiteHostname', response)
      })
      .catch((error) => {
        commit('setLoading', false)
        showToast(this, error.message, `danger`)
      })
  },
  async checkSiteStatusAdmin({ commit, state }, website) {
    let loading = true
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    while (loading === true) {
      await this.$axios
        .$get('api/sites', {
          params: {
            hostname: `eq.${website.hostname}`
          },
          headers: {
            Authorization: 'Bearer ' + state.token,
            ...specificHeaders.singleObject
          }
        })
        .then((response) => {
          if (response.status === 'Redirect') {
            website.hostname = response.redirect_hostname
          } else if (response.status === 'Done') {
            loading = false
            this.$router.push({
              path: '/2af3RT5Op1vb-7Weos385-TY94/sites/' + response.hostname
            })
          } else if (response.status === 'Timeout') {
            loading = false
            showToast(this, 'Request to the Server Timed Out', `danger`)
          } else if (response.status === 'Error') {
            loading = false
            showToast(this, 'OPPS! Error in obtaining results', `danger`)
          }
        })
        .catch((error) => {
          console.log(error)
          loading = false
        })
      await delay(3000)
    }
  },
  async getSiteList({ commit }, { query, token }) {
    if (query.status === undefined) {
      query.status = 'Done'
    }
    if (query.limit === undefined) {
      query.limit = 10
    }
    await this.$axios({
      method: 'get',
      url: 'api/sites',
      headers: {
        Authorization: 'Bearer ' + token,
        ...specificHeaders.count
      },
      params: {
        hostname: query.hostname,
        status: 'eq.' + query.status,
        limit: query.limit,
        offset: query.limit * (query.page_no - 1)
      }
    })
      .then((response) => {
        commit('sitesList', response)
      })
      .catch((error) => {
        if (error !== undefined) {
          if (error.response !== undefined) {
            if (error.response.status === 401) {
              commit('setAdminToken', null)
            } else {
              console.log(error)
            }
          }
        } else {
          console.log(error)
        }
      })
  },
  nuxtServerInit({ commit }, { req }) {
    let auth = null
    if (req.headers.cookie) {
      const parsed = cookieparser.parse(req.headers.cookie)
      try {
        auth = parsed.token
      } catch (err) {
        console.log(err)
      }
    }
    commit('setAdminToken', auth)
  },
  async getSiteErrorsAdmin({ commit, state }, { params }) {
    await this.$axios
      .$get('api/sites_errors', {
        params: {
          hostname: `eq.${params.hostname}`,
          order: 'rule_status.asc'
        },
        headers: {
          Authorization: 'Bearer ' + state.token
        }
      })
      .then((response) => {
        commit('setSiteErrors', response)
      })
      .catch((error) => {
        if (error !== undefined) {
          if (error.response !== undefined) {
            if (error.response.status === 401) {
              commit('setAdminToken', null)
            } else {
              console.log(error)
            }
          }
        } else {
          console.log(error)
        }
      })
  },
  async getSiteScoreAdmin({ commit, state }, { params, query, error }) {
    await this.$axios
      .$get(`api/sites?hostname=eq.${params.hostname}&select=score`, {
        headers: {
          Authorization: 'Bearer ' + state.token,
          ...specificHeaders.singleObject
        }
      })
      .then((response) => {
        commit('setSiteScore', response)
      })
      .catch((error) => {
        if (error !== undefined) {
          if (error.response !== undefined) {
            console.log(error.response.status + ' User session timed out')
          }
        } else {
          console.log(error)
        }
      })
  },
  async getPageListAdmin({ commit, state }, { params }) {
    await this.$axios
      .$get('api/sites', {
        paramsSerializer: (params) => {
          return qs.stringify(params, { allowDots: true })
        },
        params: {
          hostname: `eq.${params.hostname}`,
          select: 'page_site_id_fkey(url,page_id,score,pages_errors(*))',
          page_site_id_fkey: {
            pages_errors: { order: 'rule_status.asc' },
            order: 'url.asc'
          }
        },
        headers: {
          Authorization: 'Bearer ' + state.token,
          ...specificHeaders.singleObject
        }
      })
      .then((response) => {
        commit('setPageList', response.page_site_id_fkey)
      })
      .catch((error) => {
        if (error !== undefined) {
          if (error.response !== undefined) {
            console.log(error.response.status + ' User session timed out')
          }
        } else {
          console.log(error)
        }
      })
  }
}
