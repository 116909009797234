<template>
  <div id="app">
    <Header />
    <nuxt id="app-container" />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/AdminHeader'
import Footer from '@/components/Footer'
export default {
  components: {
    Header,
    Footer
  }
}
</script>
