export default function({ $axios, $sentry, redirect }) {
  $axios.onRequest((config) => {
    console.log('Making request to ' + config.url)
    console.log(Date())
  })
  $axios.onResponse((response) => {
    console.log('response =>' + Date())
  })
  $axios.onError((e) => {
    $sentry.captureException(e)
    console.log(Date())
    if (e.response.status === 406) {
      redirect('/')
    }
  })
}
