import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6c341ede = () => interopDefault(import('../pages/2af3RT5Op1vb-7Weos385-TY94/index.vue' /* webpackChunkName: "pages/2af3RT5Op1vb-7Weos385-TY94/index" */))
const _3d3f7aca = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _9211e26e = () => interopDefault(import('../pages/verify.vue' /* webpackChunkName: "pages/verify" */))
const _04dc270e = () => interopDefault(import('../pages/2af3RT5Op1vb-7Weos385-TY94/sites/index.vue' /* webpackChunkName: "pages/2af3RT5Op1vb-7Weos385-TY94/sites/index" */))
const _9c64a45e = () => interopDefault(import('../pages/2af3RT5Op1vb-7Weos385-TY94/sites/_hostname/index.vue' /* webpackChunkName: "pages/2af3RT5Op1vb-7Weos385-TY94/sites/_hostname/index" */))
const _0b89783c = () => interopDefault(import('../pages/2af3RT5Op1vb-7Weos385-TY94/sites/_hostname/_id/index.vue' /* webpackChunkName: "pages/2af3RT5Op1vb-7Weos385-TY94/sites/_hostname/_id/index" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _20362cf5 = () => interopDefault(import('../pages/_hostname/index.vue' /* webpackChunkName: "pages/_hostname/index" */))
const _0595c560 = () => interopDefault(import('../pages/_hostname/_id/index.vue' /* webpackChunkName: "pages/_hostname/_id/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/2af3RT5Op1vb-7Weos385-TY94",
    component: _6c341ede,
    name: "2af3RT5Op1vb-7Weos385-TY94"
  }, {
    path: "/cookie-policy",
    component: _3d3f7aca,
    name: "cookie-policy"
  }, {
    path: "/verify",
    component: _9211e26e,
    name: "verify"
  }, {
    path: "/2af3RT5Op1vb-7Weos385-TY94/sites",
    component: _04dc270e,
    name: "2af3RT5Op1vb-7Weos385-TY94-sites"
  }, {
    path: "/2af3RT5Op1vb-7Weos385-TY94/sites/:hostname",
    component: _9c64a45e,
    name: "2af3RT5Op1vb-7Weos385-TY94-sites-hostname"
  }, {
    path: "/2af3RT5Op1vb-7Weos385-TY94/sites/:hostname?/:id",
    component: _0b89783c,
    name: "2af3RT5Op1vb-7Weos385-TY94-sites-hostname-id"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/:hostname",
    component: _20362cf5,
    name: "hostname"
  }, {
    path: "/:hostname/:id",
    component: _0595c560,
    name: "hostname-id"
  }],

  fallback: false
}

export function createRouter () {
  const router = new Router(routerOptions)
  const resolve = router.resolve.bind(router)

  // encodeURI(decodeURI()) ~> support both encoded and non-encoded urls
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = encodeURI(decodeURI(to))
    }
    return resolve(to, current, append)
  }

  return router
}
