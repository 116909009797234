export default {
  getSiteHostname: (state) => {
    return state.siteHostname
  },
  getSiteErrors: (state) => {
    return state.siteDetails && state.siteDetails.sites_errors
  },
  getSiteScore: (state) => {
    return state.siteDetails && state.siteDetails.score
  },
  getSiteStatus: (state) => {
    return state.siteDetails && state.siteDetails.status
  },
  getPageList: (state) => {
    return state.siteDetails && state.siteDetails.page_site_id_fkey
  },
  getLoading: (state) => {
    return state.loading
  },
  getErrorMsg: (state) => {
    return state.errMsg
  },
  getSiteDetails: (state) => {
    return state.siteDetails
  },
  getPageDetails: (state) => {
    return state.pageDetails
  },
  siteShowCaseData: (state) => {
    return state.siteShowCaseData
  },
  // -------------------Admin --------------------------
  siteList: (state) => {
    return state.siteList
  },
  getToken: (state) => {
    return state.token
  },
  siteCount: (state) => {
    return state.SiteCount
  }
}
