export default () => ({
  siteHostname: null,
  wait: null,
  siteErrors: null,
  siteScore: null,
  pageList: null,
  loading: null,
  errMsg: null,
  siteDetails: null,
  pageDetails: null,
  siteShowCaseData: null,

  // ---------------------Admin -----------
  siteList: null,
  token: null,
  SiteCount: null
})
